import React from "react"
import SunCalc from "SunCalc"
import { Helmet } from "react-helmet"

class About extends React.Component {
  render() {
  const lat = 53.4808
  const lon = -2.2426
  const today = new Date()
  const tomorrow = new Date()
  tomorrow.setDate(today.getDate() + 1)

  const todayCalcs = SunCalc.getTimes(today, lat, lon);
  const tomorrowCalcs = SunCalc.getTimes(tomorrow, lat, lon);
  return (
    <div>
      <Helmet><body className="darkness" /></Helmet>
      <div class="info">
        <div class="sunset">Sunset today ({today.toLocaleDateString()}) is {todayCalcs.sunsetStart.toLocaleTimeString()}</div>
        <div class="sunrise">Sunrise tomorrow ({tomorrow.toLocaleDateString()}) is {tomorrowCalcs.sunrise.toLocaleTimeString()}</div>
      </div>
      <div class="float_heart">
        <div class="heart"></div>
      </div>
      <div class="animation">
        <img class="goose" src="goose.png" />
      </div>
    </div>
  )}
}

export default About
